<template>
	<div class="user-page__wrapper">
		<div class="user-page">
			<UserMenu />
			<div class="user-page__content">
				<h2>Attendees List</h2>
				<div class="tabs dcp__tabs">
					<ul>
						<li :class="{ 'is-active': listFilter === 'all' }"><a @click.prevent="changeList('all')">All</a></li>
						<li v-if="showMatchmaking" :class="{ 'is-active': listFilter === 'matchmaking' }"><router-link :to="{ name: 'Matchmaking' }">Matchmaking</router-link></li>
						<li :class="{ 'is-active': listFilter === 'con' }"><a @click.prevent="changeList('con')">My Connections</a></li>
						<li :class="{ 'is-active': listFilter === 'req' }"><a @click.prevent="changeList('req')">Pending Requests<span v-if="pendingCount > 0">&nbsp;({{ pendingCount }})</span></a></li>
					</ul>
				</div>

				<div class="attendees__search">
					<div class="control has-icons-right">
						<span class="icon is-right">
							<inline-svg :src="require('../assets/search.svg')" width="28"></inline-svg>
						</span>
						<input v-model="search" class="input" type="text" placeholder="Start typing to search attendees" />
					</div>
					<button :class="{ 'is-active': activeFilters }" @click.prevent="toggleMore">
						<inline-svg :src="require('../assets/filter.svg')" width="28"></inline-svg>
						<span v-if="activeFilters" class="filter-counter">{{ activeFilters }}</span>
					</button>
					<div class="attendees__search__more" :class="{ 'is-open': searchMore }">
						<div class="columns is-multiline">
							<div class="column is-half">
								<div class="field">
									<label class="label">Profession</label>
									<div class="control">
										<v-select v-model="selectedProfessions" multiple :options="professions" label="name"></v-select>
									</div>
								</div>
							</div>
							<div class="column is-half">
								<div class="field">
									<label class="label">Working Place</label>
									<div class="control">
										<v-select v-model="selectedWorkingPlaces" multiple :options="workingPlaces" label="name"></v-select>
									</div>
								</div>
							</div>
							<div class="column is-half">
								<div class="field">
									<label class="label">Country</label>
									<div class="control">
										<v-select v-model="selectedCountries" multiple :options="countries" label="name"></v-select>
									</div>
								</div>
							</div>
							<div class="column is-half">
								<div class="field">
									<label class="label">Location</label>
									<div class="control">
										<v-select v-model="selectedLocations" multiple :options="locations" label="name"></v-select>
									</div>
								</div>
							</div>
							<div class="column is-half">
								<label class="label">Avatars</label>
								<label class="checkbox is-centered">
									<input v-model="selectedImages" type="checkbox" />
									Only users with avatars
								</label>
							</div>
							<div class="column is-half has-text-right attendees__search__buttons">
								<a class="button is-primary is-outlined" @click="resetFilters(true)">Clear All</a>
								<a class="button is-primary" @click="toggleMore(true)">Apply</a>
							</div>
						</div>
					</div>
				</div>
				<div class="attendees__wrapper">
					<div v-if="attendeeLoading" class="loader-wrapper is-active">
						<div class="loader is-loading"></div>
					</div>
					<div v-if="!attendeeLoading && attendees.length === 0">
						<p class="has-text-centered">No attendees found</p>
					</div>

					<div class="attendees__list">
						<div v-for="attendee in attendees" :key="attendee.id" class="attendees__box">
							<router-link class="image" :to="{ name: 'AttendeeDetail', params: { id: attendee.id } }">
								<img v-if="hasFrame(attendee)" :src="platformSettings.profileFrameImage | imageUrl" class="frame" />
								<inline-svg v-if="!attendee.image && attendee.gender === 'Male'" :src="require('../assets/user_male.svg')" width="25" />
								<inline-svg v-else-if="!attendee.image && attendee.gender === 'Female'" :src="require('../assets/user_female.svg')" width="25" />
								<inline-svg v-else-if="!attendee.image" :src="require('../assets/user_generic.svg')" width="25" />
								<img v-else :src="attendee.image | imageUrl('thumbnail')" />
							</router-link>
							<router-link :to="{ name: 'AttendeeDetail', params: { id: attendee.id } }"
								><strong>{{ attendee.firstName }} {{ attendee.lastName }}</strong></router-link
							>
							<span>{{ attendee.job_title }} <span v-if="attendee.job_title && attendee.company">at</span> {{ attendee.company }}</span>
							<div v-if="session.user.id !== attendee.id" class="attendees__actions">
								<a
									v-if="attendee.userType === 'normal' && listFilter !== 'con'"
									class="has-tooltip-bottom has-tooltip-fade"
									data-tooltip="Connect"
									@click.prevent="sendFriendRequest(attendee)"
								>
									<inline-svg :src="require('../assets/plus.svg')" width="20" />
								</a>
								<a
									v-if="attendee.userType === 'incomingRequest'"
									class="has-tooltip-bottom has-tooltip-fade"
									data-tooltip="Reject connection"
									@click.prevent="rejectFriendRequest(attendee)"
								>
									<inline-svg :src="require('../assets/delete.svg')" width="20" />
								</a>
								<a
									v-if="attendee.userType === 'incomingRequest'"
									class="has-tooltip-bottom has-tooltip-fade"
									data-tooltip="Accept connection"
									@click.prevent="acceptFriendRequest(attendee)"
								>
									<inline-svg :src="require('../assets/ok.svg')" width="20" />
								</a>
								<router-link v-if="platformSettings.chat" :to="{ name: 'Chat', params: { user: attendee.id } }" class="has-tooltip-bottom has-tooltip-fade" data-tooltip="Send message">
									<inline-svg :src="require('../assets/send.svg')" width="20" />
								</router-link>
							</div>
							<div v-if="attendee.userType === 'friend'" class="attendees__unfriend">
								<a @click.prevent="removeFriend(attendee)">
									<inline-svg :src="require('../assets/delete.svg')" width="14" />
								</a>
							</div>
						</div>
					</div>
					<div v-if="pageCount > 1" class="attendees__pagination">
						<Paginate :page-count="pageCount" :click-handler="onPaginate" :prev-text="'Prev'" :next-text="'Next'" :container-class="'pagination'"> </Paginate>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import UserMenu from '../components/UserMenu';
	import $http from '../utils/http';
	import session from '../utils/session';
	import ConfirmModal from '../components/ConfirmModal';
	import Paginate from 'vuejs-paginate';
	import Vue from 'vue';
	import ProfileVisibilityModal from '../components/ProfileVisibilityModal';
	import platformSettings from '../utils/platformSettings';

	const debounce = (fn, delay) => {
		var timeoutID = null;
		return function() {
			clearTimeout(timeoutID);
			var args = arguments;
			var that = this;
			timeoutID = setTimeout(function() {
				fn.apply(that, args);
			}, delay);
		};
	};

	const PER_PAGE = 51;

	export default {
		name: 'Attendees',
		components: { UserMenu, Paginate },
		data() {
			return {
				search: '',
				listFilter: 'all',
				debouncedSearch: '',
				attendees: [],
				selectedProfessions: [],
				selectedWorkingPlaces: [],
				selectedCountries: [],
				selectedLocations: [],
				selectedImages: false,
				attendeeLoading: true,
				total: 0,
				page: 1,
				// activeFilters: 0,
				lastActiveFilters: {
					selectedProfessions: [],
					selectedWorkingPlaces: [],
					selectedCountries: [],
					selectedLocations: [],
					selectedImages: false
				},

				searchMore: false,
				session: session.state,
				professions: [],
				workingPlaces: [],
				locations: [],
				countries: [],
				platformSettings: platformSettings.state,
				pendingCount: 0,
			};
		},
		beforeRouteEnter(to, from, next) {
			Vue.prototype.$Progress.finish();
			if (session.state.user.public_profile === true) {
				next();
			} else {
				if (!from || !from.name) {
					next({ name: 'EditProfile' });
				} else {
					Vue.prototype.$modal.show(
						ProfileVisibilityModal,
						{},
						{
							classes: 'login-modal',
							width: '90%',
							maxWidth: 600,
							height: 'auto',
							adaptive: true,
						},
						{
							'before-close': ({ params }) => {
								if (params === true) {
									next({ name: 'EditProfile' });
								} else {
									next(false);
								}
							},
						}
					);
				}
			}
		},
		beforeRouteUpdate(to, from, next) {
			Vue.prototype.$Progress.finish();
			if (session.state.user.public_profile === true) {
				next();
			} else {
				if (!from || !from.name) {
					next({ name: 'EditProfile' });
				} else {
					Vue.prototype.$modal.show(
						ProfileVisibilityModal,
						{},
						{
							classes: 'login-modal',
							width: '90%',
							maxWidth: 600,
							height: 'auto',
							adaptive: true,
						},
						{
							'before-close': ({ params }) => {
								if (params === true) {
									next({ name: 'EditProfile' });
								} else {
									next(false);
								}
							},
						}
					);
				}
			}
		},
		computed: {
			pageCount() {
				return Math.ceil(this.total / PER_PAGE);
			},
			showMatchmaking() {
				return this.platformSettings.matchmakingEnabled === true;
			},
			activeFilters() {
				let c = 0;
				if (this.selectedProfessions.length > 0) c++;
				if (this.selectedWorkingPlaces.length > 0) c++;
				if (this.selectedCountries.length > 0) c++;
				if (this.selectedLocations.length > 0) c++;
				if (this.selectedImages) c++;
				return c;
			},
		},
		mounted() {
			if (this.$route.params.activeFilter) {
				this.listFilter = this.$route.params.activeFilter;
			}

			this.loadCountries();
			this.doSearch();
			this.loadPendingCount();
		},
		watch: {
			search: debounce(function(newVal) {
				this.debouncedSearch = newVal;
			}, 500),
			debouncedSearch() {
				this.doSearch();
			},
		},
		methods: {
			loadCountries() {
				$http.get('/countries?_limit=300').then(({ data }) => {
					this.countries = data;
				});
				$http.get('/geographical-locations').then(({ data }) => {
					this.locations = data;
				});
				$http.get(`user-organization-types`).then(({ data }) => {
					this.workingPlaces = data;
				});
				$http.get(`user-professions`).then(({ data }) => {
					this.professions = data;
				});
			},
			loadPendingCount() {
				$http.get('/users/network?_start=0&_limit=51&pending_reqs=true').then((res) => {
					if (res.data && 'total' in res.data) {
						this.pendingCount = res.data.total;
					}
				});
			},
			doSearch() {
				this.attendeeLoading = true;
				let params = new URLSearchParams();

				params.append('_start', (this.page - 1) * PER_PAGE);
				params.append('_limit', PER_PAGE);

				if (this.debouncedSearch.length > 1) {
					params.append('name', this.debouncedSearch);
				}

				if (this.listFilter === 'con') {
					params.append('my_connections', true);
				}

				if (this.listFilter === 'req') {
					params.append('pending_reqs', true);
				}

				if (this.selectedProfessions.length > 0) {
					this.selectedProfessions.map(e => {
						params.append('profession', e.id);
					});
				}

				if (this.selectedWorkingPlaces.length > 0) {
					this.selectedWorkingPlaces.map(e => {
						params.append('organization_type', e.id);
					});
				}

				if (this.selectedCountries.length > 0) {
					this.selectedCountries.map(e => {
						params.append('country', e.id);
					});
				}

				if (this.selectedLocations.length > 0) {
					this.selectedLocations.map(e => {
						params.append('location', e.id);
					});
				}

				if (this.selectedImages === true) {
					params.append('image', true);
				}

				$http
					.get('/users/network', {
						params,
					})
					.then(({ data }) => {
						this.attendees = [];
						this.total = data.total;
						if (this.listFilter === 'req') {
							this.pendingCount = data.total;
						}
						if (data.users && data.users.length > 0) {
							this.attendees = data.users;
							this.page = 1;
						}
						this.attendeeLoading = false;
					});

				// this.searchFilterCount();
			},
			toggleMore(search) {
				this.searchMore = !this.searchMore;

				if (search === true) {
					this.doSearch();
					this.copyActiveFilters();
				}

				this.pasteActiveFilters();

				if (!this.activeFilters) this.resetFilters();
			},
			pasteActiveFilters() {
				this.selectedProfessions = this.lastActiveFilters.selectedProfessions;
				this.selectedWorkingPlaces = this.lastActiveFilters.selectedWorkingPlaces;
				this.selectedCountries = this.lastActiveFilters.selectedCountries;
				this.selectedLocations = this.lastActiveFilters.selectedLocations;
				this.selectedImages = this.lastActiveFilters.selectedImages;
			},
			copyActiveFilters() {
				this.lastActiveFilters.selectedProfessions = this.selectedProfessions;
				this.lastActiveFilters.selectedWorkingPlaces = this.selectedWorkingPlaces;
				this.lastActiveFilters.selectedCountries = this.selectedCountries;
				this.lastActiveFilters.selectedLocations = this.selectedLocations;
				this.lastActiveFilters.selectedImages = this.selectedImages;
			},
			resetFilters(apply) {
				this.selectedProfessions = [];
				this.selectedWorkingPlaces = [];
				this.selectedCountries = [];
				this.selectedLocations = [];
				this.selectedImages = false;

				if (apply) {
					this.searchMore = !this.searchMore;
					this.doSearch();
					this.copyActiveFilters();
					this.pasteActiveFilters();
				}
			},
			changeList(type) {
				this.listFilter = type;
				this.doSearch();
			},
			sendFriendRequest(attendee) {
				if (attendee && attendee.id && this.session.user.id) {
					$http
						.post('/friend-requests', {
							author: this.session.user.id,
							recipient: attendee.id,
						})
						.then(data => {
							attendee.userType = 'outgoingRequest';
							this.$set(this.attendees, this.attendees.indexOf(attendee), attendee);
						});
				}
			},
			removeFriend(attendee) {
				if (attendee && attendee.id) {
					this.$modal.show(
						ConfirmModal,
						{
							title: 'Remove Connection?',
							text: `Would you like to removed ${attendee.firstName} ${attendee.lastName} from your connections?`,
						},
						{
							classes: 'login-modal',
							width: '90%',
							maxWidth: 550,
							height: 'auto',
							adaptive: true,
						},
						{
							'before-close': ({ params }) => {
								if (params === true) {
									$http.delete(`/unfriend-person/${attendee.id}`).then(() => {
										attendee.userType = 'normal';
										if (this.listFilter === 'con') {
											this.$delete(this.attendees, this.attendees.indexOf(attendee));
										} else {
											this.$set(this.attendees, this.attendees.indexOf(attendee), attendee);
										}
									});
								}
							},
						}
					);
				}
			},
			rejectFriendRequest(attendee) {
				if (attendee && attendee.in_req) {
					this.$modal.show(
						ConfirmModal,
						{
							title: 'Reject Connection?',
							text: `Would you like to reject ${attendee.firstName} ${attendee.lastName} connection request?`,
						},
						{
							classes: 'login-modal',
							width: '90%',
							maxWidth: 550,
							height: 'auto',
							adaptive: true,
						},
						{
							'before-close': ({ params }) => {
								if (params === true) {
									$http.delete(`/reject-friend-request/${parseInt(attendee.in_req)}`).then(() => {
										this.pendingCount = this.pendingCount - 1;
										delete attendee.in_req;
										attendee.userType = 'normal';
										if (this.listFilter === 'req') {
											this.$delete(this.attendees, this.attendees.indexOf(attendee));
										} else {
											this.$set(this.attendees, this.attendees.indexOf(attendee), attendee);
										}
									});
								}
							},
						}
					);
				}
			},
			acceptFriendRequest(attendee) {
				if (attendee && attendee.in_req) {
					$http.put(`/confirm-friend-request/${parseInt(attendee.in_req)}`).then(() => {
						this.pendingCount = this.pendingCount - 1;
						delete attendee.in_req;
						attendee.userType = 'friend';
						if (this.listFilter === 'req') {
							this.$delete(this.attendees, this.attendees.indexOf(attendee));
						} else {
							this.$set(this.attendees, this.attendees.indexOf(attendee), attendee);
						}
					});
				}
			},
			onPaginate(newPage) {
				this.page = newPage;
				this.doSearch();
			},
			hasFrame(attendee) {
				return (
					attendee.eaaci_membership === true && attendee.profile_frame_enabled === true && this.platformSettings.profileFrameEnabled && this.platformSettings.profileFrameImage
				);
			},
		},
	};
</script>
