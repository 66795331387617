<template>
	<div>
		<div class="user-page__wrapper">
			<div class="user-page">
				<UserMenu />
				<div class="user-page__content attendee-detail">
					<div class="attendee-detail__header">
						<div class="attendee-detail__image">
							<div class="image">
								<img v-if="hasFrame" :src="platformSettings.profileFrameImage | imageUrl" class="frame" />
								<inline-svg v-if="!attendee.image && attendee.gender === 'Male'" :src="require('../assets/user_male.svg')" width="25" />
								<inline-svg v-else-if="!attendee.image && attendee.gender === 'Female'" :src="require('../assets/user_female.svg')" width="25" />
								<inline-svg v-else-if="!attendee.image" :src="require('../assets/user_generic.svg')" width="25" />
								<img v-else :src="attendee.image | imageUrl('small')" />
							</div>
							<a v-if="!isCurrentUser && !isFriend && !friendRequestSent && !attendee.outgoing_req && !attendee.incoming_req" class="button is-primary" @click="sendFriendRequest">
								<inline-svg :src="require('../assets/user-add.svg')" width="25"></inline-svg>
								Connect
							</a>
							<a v-if="!isCurrentUser && isFriend && !friendRequestSent && !attendee.outgoing_req" class="button is-primary" @click="removeFriend">
								<inline-svg :src="require('../assets/user-check.svg')" width="25"></inline-svg>
								Connected
							</a>
							<div v-if="this.attendee.incoming_req" class="attendee-detail__pending">
								<span>Pending Request</span>
								<span>
									<a class="has-tooltip-bottom has-tooltip-fade" data-tooltip="Reject connection" @click.prevent="rejectFriendRequest()">
										<inline-svg :src="require('../assets/delete.svg')" width="20" />
									</a>
									<a class="has-tooltip-bottom has-tooltip-fade" data-tooltip="Accept connection" @click.prevent="acceptFriendRequest()">
										<inline-svg :src="require('../assets/ok.svg')" width="20" />
									</a>
								</span>
							</div>

							<router-link v-if="platformSettings.chat && !isCurrentUser" :to="{ name: 'Chat', params: { user: attendee.id } }" class="button is-primary">
								<inline-svg :src="require('../assets/topic.svg')" width="20"></inline-svg>
								Chat
							</router-link>
						</div>
						<div class="attendee-detail__back">
							<router-link :to="{ name: 'Attendees' }">
								<span class="icon"><inline-svg :src="require('../assets/back.svg')" width="26"></inline-svg></span>
								<span>Back</span>
							</router-link>
						</div>
					</div>
					<div class="attendee-detail__content">
						<h2>{{ attendee.firstName }} {{ attendee.lastName }}</h2>
						<h3>{{ attendee.job_title }} <span v-if="attendee.job_title && attendee.company">at</span> {{ attendee.company }}</h3>

						<ul class="attendee-detail__info">
							<li v-if="attendee.country">
								<inline-svg :src="require('../assets/pin.svg')" width="24"></inline-svg>
								<span>{{ attendee.country }}</span>
							</li>
							<li v-if="attendee.email">
								<inline-svg :src="require('../assets/mail.svg')" width="24"></inline-svg>
								<span>{{ attendee.email }}</span>
							</li>
						</ul>

						<h4 v-if="attendee.bio">Bio</h4>
						<p>{{ attendee.bio }}</p>

						<div class="attendee-detail__social">
							<a v-for="social in socialNetworks" :href="social.url | fixUrl" target="_blank" @click="externalLink($event, $options.filters.fixUrl(social.url))">
								<span v-if="social.type === 'linkedin'"><inline-svg :src="require('../assets/linkedin.svg')" width="25"></inline-svg></span>
								<span v-if="social.type === 'twitter'"><inline-svg :src="require('../assets/twitter.svg')" width="25"></inline-svg></span>
								<span v-if="social.type === 'facebook'"><inline-svg :src="require('../assets/facebook.svg')" width="25"></inline-svg></span>
								<span v-if="social.type === 'instagram'"><inline-svg :src="require('../assets/instagram.svg')" width="25"></inline-svg></span>
							</a>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import $http from '../utils/http';
	import meta from '../utils/meta';
	import UserMenu from '../components/UserMenu';
	import session from '../utils/session';
	import ConfirmModal from '../components/ConfirmModal';
	import ExternalDisclaimer from '../components/ExternalDisclaimer';
	import platformSettings from '../utils/platformSettings';

	export default {
		name: 'AttendeeDetail',
		components: { UserMenu },
		filters: {
			fixUrl(url) {
				if (!url) return false;
				if (url.startsWith('https://')) {
					return url;
				} else if (url.startsWith('http://')) {
					return url.replace('http://', 'https://');
				} else {
					return `https://${url}`;
				}
			},
		},
		data() {
			return {
				attendee: {},
				title: 'Attendee',
				subtitle: '',
				background: '',
				session: session.state,
				friendRequestSent: false,
				platformSettings: platformSettings.state,
			};
		},
		computed: {
			isFriend() {
				return this.attendee.email !== null || this.attendee.friend === true;
			},
			isCurrentUser() {
				return this.attendee.id === this.session.user.id;
			},
			hasFrame() {
				return (
					this.attendee.eaaci_membership === true &&
					this.attendee.profile_frame_enabled === true &&
					this.platformSettings.profileFrameEnabled &&
					this.platformSettings.profileFrameImage
				);
			},
			socialNetworks() {
				if (!this.attendee.social_networks) return false;
				return this.attendee.social_networks.filter(e => !!e.url);
			},
		},
		beforeRouteEnter(to, from, next) {
			$http.all([$http.get(`/users/network/${to.params.id}`)]).then(
				$http.spread(data => {
					next(vm => {
						vm.setData(data);
					});
				})
			);
		},
		beforeRouteUpdate(to, from, next) {
			$http.all([$http.get(`/users/network/${to.params.id}`)]).then(
				$http.spread(data => {
					this.setData(data);
					next();
				})
			);
		},
		methods: {
			setPage({ data }) {
				if (data.title) {
					this.title = data.title;
				}
				meta.setTitle(this.title);
				this.subtitle = data.subtitle;
				this.background = data.background;
			},
			setData(data) {
				this.attendee = data.data;
			},
			sendFriendRequest() {
				if (this.session.user.id) {
					$http
						.post('/friend-requests', {
							author: this.session.user.id,
							recipient: this.attendee.id,
						})
						.then(() => {
							this.friendRequestSent = true;
						});
				}
			},
			removeFriend() {
				this.$modal.show(
					ConfirmModal,
					{
						title: 'Remove Connection?',
						text: `Would you like to removed ${this.attendee.firstName} ${this.attendee.lastName} from your connections?`,
					},
					{
						classes: 'login-modal',
						width: '90%',
						maxWidth: 550,
						height: 'auto',
						adaptive: true,
					},
					{
						'before-close': ({ params }) => {
							if (params === true) {
								$http.delete(`/unfriend-person/${this.attendee.id}`).then(() => {
									this.attendee.email = null;
								});
							}
						},
					}
				);
			},
			rejectFriendRequest() {
				if (this.attendee.incoming_req) {
					this.$modal.show(
						ConfirmModal,
						{
							title: 'Reject Connection?',
							text: `Would you like to reject ${this.attendee.firstName} ${this.attendee.lastName} connection request?`,
						},
						{
							classes: 'login-modal',
							width: '90%',
							maxWidth: 550,
							height: 'auto',
							adaptive: true,
						},
						{
							'before-close': ({ params }) => {
								if (params === true) {
									$http.delete(`/reject-friend-request/${parseInt(this.attendee.incoming_req)}`).then(() => {
										this.attendee.incoming_req = null;
									});
								}
							},
						}
					);
				}
			},
			acceptFriendRequest() {
				if (this.attendee.incoming_req) {
					$http.put(`/confirm-friend-request/${parseInt(this.attendee.incoming_req)}`).then(() => {
						this.attendee.incoming_req = null;
						this.$set(this.attendee, 'friend', true);
					});
				}
			},
			externalLink(ev, url) {
				console.log(url);
				ev.preventDefault();
				this.$modal.show(
					ExternalDisclaimer,
					{
						url: url,
						target: true,
					},
					{
						classes: 'external-disclaimer',
						width: '90%',
						maxWidth: 550,
						height: 'auto',
						adaptive: true,
					}
				);
			},
		},
	};
</script>

<style scoped></style>
