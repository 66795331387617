<template>
	<div>
		<h1>Profile Visibility</h1>
		<p>Your profile is currently hidden.</p>
		<p>To establish connections, send messages and have access to the attendees list, <strong>your profile must be set to public</strong>.</p>
		<div class="buttons">
			<a class="button is-primary" @click="ok()">Edit Profile</a>
			<a class="button is-primary is-outlined" @click="cancel()">No, cancel</a>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'LoginFailedModal',
		props: {
			to: Object,
		},
		data() {
			return {};
		},
		methods: {
			ok() {
				this.$emit('close', true);
			},
			cancel() {
				this.$emit('close', false);
			},
		},
	};
</script>
