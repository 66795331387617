<template>
	<div>
		<h1>{{ title }}</h1>
		<p>{{ text }}</p>
		<div class="buttons">
			<a class="button is-primary" @click="confirm()">Yes</a>
			<a class="button is-primary is-outlined" @click="cancel()">No</a>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'ConfirmModal',
		props: {
			title: String,
			text: String,
		},
		data() {
			return {};
		},
		methods: {
			confirm() {
				this.$emit('close', true);
			},
			cancel() {
				this.$emit('close', false);
			},
		},
	};
</script>
